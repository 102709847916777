/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'stats': {
    width: 17,
    height: 16,
    viewBox: '0 0 17 16',
    data: '<mask id="svgicon_stats_a" fill="#fff"><rect pid="0" width="6.263" height="11.2" rx="1"/></mask><rect pid="1" width="6.263" height="11.2" rx="1" transform="translate(0 4.8)" _stroke="#898B9B" stroke-width="3" mask="url(#svgicon_stats_a)"/><mask id="svgicon_stats_b" _fill="#fff"><rect pid="2" width="6.263" height="16" rx="1"/></mask><rect pid="3" width="6.263" height="16" rx="1" transform="translate(5.368 -.001)" _stroke="#898B9B" stroke-width="3" mask="url(#svgicon_stats_b)"/><mask id="svgicon_stats_c" _fill="#fff"><rect pid="4" width="6.263" height="8" rx="1"/></mask><rect pid="5" width="6.263" height="8" rx="1" transform="translate(10.737 7.999)" _stroke="#898B9B" stroke-width="3" mask="url(#svgicon_stats_c)"/>'
  }
})
